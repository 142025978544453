<template>
  <main class="match">
    <PageHeader title="Match - Critical Ops"></PageHeader>
    <l-loading v-model="matchLoaded">
      <main>
        <section class="match">
          <section v-if="match.started && !match.ended" class="panel title live">
            <span></span> LIVE MATCH
          </section>
          <section v-if="!match.started && !match.ended" class="panel title">
            Upcoming match
          </section>
          <section v-if="match.ended" class="panel title ended">
            Match over
          </section>
          <!-- Týmy se skóre --> 
          <section class="panel teams">
            <router-link class="team" 
              :to="{ name: 'Team', params: { game, team: match.teams[0]._id } }"
            >
              <div class="team-logo">
                <img :src="$cdn('team-logo', match.teams[0])"
                  :alt="match.teams[0].name"
                />
              </div>
              <div class="team-name">
                {{ match.teams[0].name }}
              </div>
            </router-link>
            <div v-if="match.started || match.ended" class="data" :class="{ ended: match.ended }">
              <div class="data-item">
                <span class="data-item-label">Best of:</span>
                <span class="data-item-value">{{ match.data.bo }}</span>
              </div>
              <div class="data-item score">
                <template v-if="match.data.bo === 1"> 
                  <p :class="{ 
                    loser: match.maps[0].score[team1] < match.maps[0].score[team2], 
                    winner: match.maps[0].score[team1] > match.maps[0].score[team2]
                  }">{{ match.maps[0].score[team1] }}</p>
                  <span>-</span>
                  <p :class="{ 
                    loser: match.maps[0].score[team2] < match.maps[0].score[team1], 
                    winner: match.maps[0].score[team2] > match.maps[0].score[team1] 
                  }">{{ match.maps[0].score[team2] }}</p>
                </template>
                <template v-if="match.data.bo > 1">
                  <p :class="{
                    loser: boScore[team1] < boScore[team2],
                    winner: boScore[team1] > boScore[team2]
                  }">{{ boScore[team1] }}</p>
                  <span>-</span>
                  <p :class="{
                    loser: boScore[team2] < boScore[team1],
                    winner: boScore[team2] > boScore[team1]
                  }">{{ boScore[team2] }}</p>
                </template>
              </div>
            </div>
            <div v-else class="data">
              <div class="data-item">
                <span class="data-item-label">Best of:</span>
                <span class="data-item-value">{{ match.data.bo }}</span>
              </div>
              <div class="data-item score">
                {{ startTime.time }}
              </div>
              <div class="data-item">
                <span class="data-item-label">
                  {{ startTime.date }}
                </span>
              </div>
            </div>
            <router-link v-if="team2" class="team" 
              :to="{ name: 'Team', params: { game, team: match.teams[1]._id } }"
            >
              <div class="team-logo">
                <img :src="$cdn('team-logo', match.teams[1])"
                  :alt="match.teams[1].name" 
                />
              </div>
              <div class="team-name">
                {{ match.teams[1].name }}
              </div>
            </router-link>
            <div v-else class="team">
              <div class="team-logo">
                
              </div>
              <div class="team-name">
                -
              </div>
            </div>
          </section>
          <!-- Event -->
          <router-link
            :to="{ name: 'Event', params: { game, event: match.event._id} }"
            custom v-slot="{ navigate }"
          >
            <section class="panel event" @click="navigate">
              <div class="event-logo">
                <img
                  :src="$cdn('event-logo', match.event)"
                  :alt="match.event.name"
                />
              </div>
              <div class="data">
                <span class="name">
                  {{ match.event.name }}
                </span>
                <div class="title">
                  {{ match.data.title }}
                </div>
              </div>
              <i class="fa-solid fa-external-link"></i>
            </section>
          </router-link>
        </section>
        <!-- Pokud se ještě zápas odehrává, zobrazit odkazy na živá vysílátní -->
        <template v-if="!match.ended && startedByTime">
          <h2>Live Streams</h2>
          <section class="live-streams">
            <a
              v-for="(live, i) in match.live"
              :key="`live${i}`"
              :href="live.link"
              target="_blank"
              class="panel stream" :class="live.type"
            >
              <i :class="`fa-brands fa-${live.type}`"></i>
              <span>{{ live.type }}</span>
              <i class="fa-solid fa-external-link"></i>
            </a>
            <span class="no-content">
              No live streams available
            </span>
          </section>
        </template>
        <!-- Mapy -->
        <h2>Maps</h2>
        <section class="maps">
          <article 
            v-for="(map, i) in match.maps"
            :key="`map${i}`"
            class="map"
          >
            <div class="map-image" :style="{ backgroundImage: `url(${$cdn('game-map-banner', { game, map: map.map})})`}">
              <div class="image-overlay"></div>
            </div>
            <div class="map-content">
              <div class="map-title">
                {{ map.map }}
              </div>
              <div class="map-teams">
                <div class="team one"
                  :class="{ 'winner': map.winner === team1, 'loser': map.winner === team2 }"
                >
                  <div class="team-logo">
                    <img :src="$cdn('team-logo', match.teams[0])"
                      :alt="match.teams[0].name" 
                    />
                  </div>
                  <div class="team-namescore">
                    <span class="team-name">
                      {{ match.teams[0].name }}
                    </span>
                    <span class="team-score">
                      {{ map.score[team1] }}
                    </span>
                  </div>
                </div>
                <div class="team two"
                  :class="{ 'winner': map.winner === team2, 'loser': map.winner === team1 }"
                >
                  <div class="team-namescore">
                    <span class="team-name">
                      {{ match.teams[1].name }}
                    </span>
                    <span class="team-score">
                      {{ map.score[team2] }}
                    </span>
                  </div>
                  <div class="team-logo">
                    <img :src="$cdn('team-logo', match.teams[1])"
                      :alt="match.teams[1].name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>
          <span v-if="match.maps.length === 0" class="no-content">
            Has not been banned yet.
          </span>
        </section>
        <!-- Pokud je zápas jíž odehrán, sobrazit záznamy -->
        <template v-if="match.ended">
          <h2>Replays</h2>
          <section class="replays">
            <template
              v-for="(replay, i) in match.replays"
            >
              <iframe v-if="replay.type === 'youtube'" class="youtube" :key="`replay${i}`" :src="`https://www.youtube.com/embed/${getYtVidId(replay.link)}`"></iframe>
              <a v-else class="panel other" :key="`replay${i}`"
                :href="replay.link"
                target="_blank"
              >
                <i
                  :class="`fa-brands fa-${replay.type}`"
                ></i>
                <span>{{ replay.type }}</span>
                <i class="fa-solid fa-external-link"></i>
              </a>
            </template>
            <span v-if="match.replays.length === 0" class="no-content">
              No replays yet
            </span>
          </section>
        </template>
        <!-- Lineups týmů -->
        <h2>Lineups</h2>
        <section class="lineups">
          <template v-for="n in 2">
            <article v-if="match.teams[n-1]" :key="`lineups-${n}`" class="panel team">
              <div class="team-header">
                <div class="team-logo">
                  <img :src="$cdn('team-logo', match.teams[n-1])"
                    :alt="match.teams[n - 1].name"
                  />
                </div>
                <div class="team-name">
                  {{ match.teams[n - 1].name }}
                </div>
              </div>
              <div class="team-lineup">
                <router-link
                  v-for="(player, i) in match.teams[n - 1].lineup"
                  :key="`player-lineup${n}-${i}`"
                  :to="{ name: 'Player', params: { game, player: player._id } }"
                  custom v-slot="{ navigate }"
                >
                  <div class="lineup-player" @click="navigate">
                    <div class="player-origin">
                      <img :src="`/icons/flags/${player.data.origin}.svg`" alt="">
                    </div>
                    <div class="player-name">
                      {{ player.name }}
                      <span>{{ player.data.name }}</span>
                    </div>
                    <i class="fa-solid fa-chevron-right"></i>
                  </div>
                </router-link>
                <div v-if="match.teams[n-1].lineup.length === 0" class="lineup-player">
                  <div class="player-origin">
                    -
                  </div>
                  <span>No lineup yet</span>
                </div>
              </div>
            </article>
          </template>
        </section>
      </main>
    </l-loading>
  </main>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
export default {
  props: {
    matchId: {
      required: true,
    },
  },
  data: () => ({
    match: null,
    matchLoaded: false,
  }),
  computed: {
    game() {
      return this.$store.state.game
    },
    team1() {
      return this.match.teams[0]._id
    },
    team2() {
      if (this.match.teams[1]) {
        return this.match.teams[1]._id
      } else {
        return null
      }
    },
    boScore() {
      let score =  this.match.maps.reduce((acc, curr) => {
        return {
          [this.team1]: curr.winner === this.team1 ? acc[this.team1] + 1 : acc[this.team1],
          [this.team2]: curr.winner === this.team2 ? acc[this.team2] + 1 : acc[this.team2],
        };
      }, {
        [this.team1]: 0,
        [this.team2]: 0,
      });
      console.log(score);
      return score;
    },
    startTime() {
      let time = moment(this.match.start);
      return {
        time: time.format("HH:mm"),
        date: time.format("DD.MM.YYYY"),
      };
    },
    startedByTime() {
      return moment(this.match.start).isBefore(moment()); 
    },
  },
  created() {
    axios.get(this.$api(`/matches/${this.matchId}`))
      .then(res => {
        this.match = res.data;
        this.matchLoaded = true;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    getYtVidId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : false;
    },
  }
}
</script>