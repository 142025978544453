<template>
  <component :is="componentName" :matchId="match"></component>
</template>

<script>
import copsMatch from "./c-ops/match.vue";
export default {
  components: {
    "c-ops-match": copsMatch,
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
    match() {
      return this.$route.params.match;
    },
    componentName() {
      return this.game + "-match";
    }
  },
}
</script>